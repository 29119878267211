import React, { Suspense } from "react";
import { Toolkit } from "@uitk/react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import "@uitk/foundations/Brand_optum/fonts.css";

const Home = React.lazy(() => import("../Home/Home.component"));
const HeaderComponent = React.lazy(() => import("../Header/Header"));
const FooterComponent = React.lazy(() => import("../Footer/Footer"));
const Auth = React.lazy(() => import("../Auth/Auth.component"));
const Unauthorized = React.lazy(() => import("../Auth/Unauthorized.component"));
const UserProvider = React.lazy(() => import("../../library/UserContext"));
const SessionTimeout = React.lazy(() => import("../../library/SessionTimer"));
const RefreshTokenTimer = React.lazy(
  () => import("../../library/RefreshTokenTimer")
);
const Search = React.lazy(() => import("../Search/Search"));
const OrganizationTable = React.lazy(
  () => import("../Organization/org-admin-list")
);
const Organization = React.lazy(
  () => import("../Organization/Organization.component")
);
const UserTable = React.lazy(() => import("../User/user-admin-list"));
const User = React.lazy(() => import("../User/User.component"));
const ReportAdminTable = React.lazy(
  () => import("../ReportsAdmin/Report-admin-list")
);
const Reports = React.lazy(() => import("../ReportsAdmin/Report.component"));
const NotificationTable = React.lazy(
  () => import("../Notifications/notifications-admin-list")
);
const Notifications = React.lazy(
  () => import("../Notifications/Notifications.component")
);
const AuthRequest = React.lazy(
  () => import("../AuthRequest/authrequest.component")
);
const SubscriptionsList = React.lazy(
  () => import("../Subscriptions/Subscriptions-list")
);
const Subscriptions = React.lazy(
  () => import("../Subscriptions/Subscriptions.component")
);
const FileUpload = React.lazy(() => import("../Fileupload/File-upload"));
const ReportViewer = React.lazy(() => import("../ReportViewer/ReportViewer"));
const NotificationViewer = React.lazy(
  () => import("../Search/ReportNotificationViewer")
);
const OrganizationNotificationViewer = React.lazy(
  () => import("../Search/OrganizationNotificationViewer")
);
const PowerBIReportViewer = React.lazy(
  () => import("../ReportViewer/PowerBIReportViewer")
);
const SpinnerPage = React.lazy(
  () => import("../SpinnerPage/SpinnerPage.component")
);
const IntakePortal = React.lazy(() => import("../../assets/IntakePortal"));
const UserGuide = React.lazy(() => import("../../assets/userGuide"));

export const App: React.FC = () => {
  return (
    <Router>
      <Toolkit appId="Gnosis" grid spacing>
        <UserProvider>
          <SessionTimeout />
          <RefreshTokenTimer />
          <HeaderComponent />
          <div className="main-content">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/auth" element={<Auth />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<Search />} />
                <Route path="/tableau" element={<ReportViewer />} />
                <Route path="/powerbi" element={<PowerBIReportViewer />} />
                <Route
                  path="/viewReportNotification"
                  element={<NotificationViewer />}
                />
                <Route
                  path="/viewOrganizationNotification"
                  element={<OrganizationNotificationViewer />}
                />
                <Route path="/logout" element={<SpinnerPage />} />
                <Route path="/orgAdmin" element={<OrganizationTable />} />
                <Route path="/userAdmin" element={<UserTable />} />
                <Route path="/org/:id" element={<Organization />} />
                <Route path="/org" element={<Organization />} />
                <Route path="/user/:id" element={<User />} />
                <Route path="/user" element={<User />} />
                <Route path="/reportAdmin" element={<ReportAdminTable />} />
                <Route path="/report" element={<Reports />} />
                <Route
                  path="/notificationadmin"
                  element={<NotificationTable />}
                />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/notifications/:id" element={<Notifications />} />
                <Route path="/report/:id" element={<Reports />} />
                <Route path="/authRequest/:id" element={<AuthRequest />} />
                <Route
                  path="/subscriptionAdmin"
                  element={<SubscriptionsList />}
                />
                <Route path="/subscription" element={<Subscriptions />} />
                <Route path="/subscription/:id" element={<Subscriptions />} />
                <Route path="/fileUpload" element={<FileUpload />} />
                <Route path="/intakePortal" element={<IntakePortal />} />
                <Route path="/userGuide" element={<UserGuide />} />
              </Routes>
            </Suspense>
          </div>
          <FooterComponent />
        </UserProvider>
      </Toolkit>
    </Router>
  );
};
